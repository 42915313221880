$gray: #d3d3d3;

.pinfield {
  border: 1px solid $gray;
  border-radius: 9px;
  font-size: 1.5rem;
  height: 67px;
  outline: none;
  text-align: center;
  transition-duration: 250ms;
  transition-property: color, border, box-shadow, transform;
  width: 45px;
  margin: 4px;
}