.cardProductsText {
    font-family: "Lato";
    font-weight: normal;
    color: #2a2a2a;
}

.colProductSection {
    display: flex;
    flex-direction: column;
}

.cardProductsSection {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 87%;
    height: 150px;
    margin: 0 auto !important;
    margin-bottom: 5%!important;
}

.colSlideSection {
    padding: 5%;
    display: flex;
}

.sliderPadding {
    --padding-top: 115px;
}

.arrowBackImage img {
    width: 100%;
    max-width: 300px;
    height: auto;
}

.rectanglePrice {
    position:absolute;
    background-color: rgba(244, 101, 36, 1);
    top:7%;
    right:0;
    width:35%;
    height: auto;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    text-align: center;
    font-family: Lato;
    font-weight: bold;
    color: #ffff
}

.fixed {
    width: 100%;
    position: sticky;
    top:80px;
}

.arrow {
    position: absolute;
}

.categoryDescription {
    margin: 20px;
    text-align: center
}

.content-bg {
    --background: #f6f6f6;
}


.grid {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.col {
    width: 40%;
}

#wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

#container {
    display: inline-block;
    position: relative;
    width: 100%;
    overflow: hidden;
}

#dummy {
    margin-top: 75%; /* 4:3 aspect ratio */
}

#card {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items:  center;
}

#element {
    margin-top: 10px;
    margin-bottom: 20px;
}

.error {
    display: flex; 
    justify-content: center; 
    align-items: center;
}

