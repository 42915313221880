/*
* Ionic React Capacitor Full App (https://store.enappd.com/product/ionic-react-full-app-capacitor/)
*
* Copyright © 2019-present Enappd. All rights reserved.
*
* This source code is licensed as per the terms found in the
* LICENSE.md file in the root directory of this source tree.
*/

/* TODO: remove these properties as they normally aren't used.
    Times New Roman is to spot easily the non-MUI formatted texts */

body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*code {
  font-family: 'Times New Roman', serif !important;
}
html.md {
  --ion-default-font: 'Times New Roman', serif !important;
}*/
.person-icon {
  color: White;
}
/* ion-header {
  --background: linear-gradient(to top right, #ffa000 0%, #ffca28 100%);
  background: linear-gradient(to top right, #ffa000 0%, #ffca28 100%);
}
ion-toolbar{
  --background: linear-gradient(to top right, #ffa000 0%, #ffca28 100%);
  background: linear-gradient(to top right, #ffa000 0%, #ffca28 100%);
} */
/* ion-title{
color: White;
} */
ion-menu-button {
  color: White;
}

.black-toolbar {
  background: black;
  text-align: center;
  font-weight: bold;
}

/* ############################################# */
/* ##################         ################## */
/* ################    FONTS    ################ */
/* ##################         ################## */
/* ############################################# */

/*
    https://google-webfonts-helper.herokuapp.com/fonts
*/


/* ######     DEFAULT MYLEMONADE FONT     ###### */

/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/poppins/poppins-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/poppins/poppins-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/poppins/poppins-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/poppins/poppins-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/poppins/poppins-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/poppins/poppins-v15-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/poppins/poppins-v15-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/poppins/poppins-v15-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/poppins/poppins-v15-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/poppins/poppins-v15-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/poppins/poppins-v15-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/poppins/poppins-v15-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/poppins/poppins-v15-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/poppins/poppins-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/poppins/poppins-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/poppins/poppins-v15-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/poppins/poppins-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/poppins/poppins-v15-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}


/* ######     CUSTOMER FONTS     ###### */

/* broadway-400 - latin */
@font-face {
  font-family: 'Broadway';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/broadway/broadway-regular.ttf');
  src: local(''),
       url('./assets/fonts/broadway/broadway-regular.ttf') format('truetype'), /* Safari, Android, iOS */
}

/* righteous-regular - latin */
@font-face {
  font-family: 'Righteous';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/righteous/righteous-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/righteous/righteous-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/righteous/righteous-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/righteous/righteous-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/righteous/righteous-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/righteous/righteous-v9-latin-regular.svg#Righteous') format('svg'); /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/open-sans/open-sans-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/open-sans/open-sans-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/open-sans/open-sans-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/open-sans/open-sans-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/open-sans/open-sans-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/open-sans/open-sans-v20-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}