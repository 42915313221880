

.no-padding {
    --padding-top: 0px;
}
.content-bg {
    --background: #f6f6f6;
}

.card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin: 10px;
}

.cardText {
    font-size: 1rem;
    font-family: "Lato";
    font-weight: bold;
    color: #2a2a2a;
    text-align: center;
    margin-top: 5%
}

.contentText {
    font-size: 1rem;
    color: #2a2a2a;
    text-align: center;
    margin-left: 1rem;
}

.grid {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.col {
    width: 40%;
}

#wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

#container {
    display: inline-block;
    position: relative;
    width: 100%;
}
#dummy {
    margin-top: 75%; /* 4:3 aspect ratio */
}
#card {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items:  center;
}

#element {
    margin-top: 10px;
    margin-bottom: 10px;
}
